<template>
    <div id="wrap">
		<div id="main">
			<h1 class="title">E_Sukmean's</h1>
			<h2 class="subtitle">잡다한 사이트. 블로그 부터해서 유용한(?) 도구들, 서비스들 까지</h2>
			<br /><br /><br />
			<nav>
				<ul style="padding:0">
					<!--<li><router-link class="b" to="/programs/">만든 프로그램들</router-link></li>-->
					<li><router-link class="b" to="/tools/">도구</router-link></li>
					<li><a class="b" href="https://blog.esukmean.com/" target="_blank">블로그</a></li>
					<li><router-link class="b" to="/service/">프로그램 / 서비스</router-link></li>
					<li><router-link class="b" to="/contact/">연락하기</router-link></li>
				</ul>  
			</nav>
			<br /><br />
		</div>
		<footer style="height:100px;background:#673ab7;color:white;padding-top:30px">
			<p>
				Copyright 2013-2024, E_Sukmean. All rights reserved.<br />무단 복제 및 개인정보(특히 이메일) 무단수집 금지.
			</p>
		</footer>
	</div>
</template>
<style scoped>
    html, body{margin:0; height:100%; min-height: 100vh}
	#wrap { 
		background:#4fc3f7;
		text-align:center; 
		min-height: 100vh
	}
	#main { 
		min-height: calc(100vh - 100px); 
		padding-top:100px; 
	}
	li{
		display:inline-block;
	}
	li a.b{
		padding:20px;
		margin:5px;
		transition-duration:0.2s;
		font-size:20px;
		border-radius:5px;
		color:#eee;
		background:#039be5;
		display:inline-block;
		min-width:150px
	}
	li a.b:hover{
		box-shadow: 0 0 5px #0288d1
	}
	h1.title { font-size: 80px; line-height: 1.5; }
</style>
<script>
export default {
	created () {
		document.title = "ESukmean's - Home";
	}
}
</script>
